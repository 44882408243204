import React, { useEffect } from 'react';
import { Button, ConfigProvider, message, Card, Flex } from 'antd';
import { useDispatch, useSelector } from 'umi';
import { ConnectState } from '@/layouts/connect';

const CountingButton: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ConnectState) => state.loading.models.main,
  );
  const { savedNumber, saved } = useSelector(
    (state: ConnectState) => state.main,
  );

  useEffect(() => {
    dispatch({
      type: 'main/fetchSavedNumber',
    });
  }, []);

  useEffect(() => {
    if (saved) {
      const localStorage = window.localStorage;
      localStorage.setItem('ifClicked', 'true');
    }
  }, [saved]);

  return (
    <Card
      style={{
        height: '30vh',
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
      }}
      styles={{
        body: {
          overflow: 'hidden',
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
        },
      }}
      bordered={false}
    >
      <Flex justify="center" align="center" style={{ marginTop: '8vh' }}>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                defaultColor: '#737373',
                defaultBorderColor: '#737373',
                defaultHoverColor: '#a29179',
                defaultHoverBorderColor: '#737373',
              },
            },
          }}
        >
          <Button
            style={{
              borderRadius: '10px',
              width: '11vw',
              height: '8vh',
              fontSize: '1.1vw',
              fontWeight: 'bold',
            }}
            loading={loading}
            onClick={() => {
              const localStorage = window.localStorage;
              const ifClicked = localStorage.getItem('ifClicked');
              if (saved || ifClicked) {
                message.info('You has clicked this button');
                return;
              }
              dispatch({
                type: 'main/updateSavedNumber',
                payload: { source: 'my7thday-ui' },
              });
            }}
          >
            I AM SAVED!
          </Button>
        </ConfigProvider>
      </Flex>
      <Flex
        vertical
        align="center"
        justify="center"
        style={{ marginTop: '1vh' }}
      >
        <span style={{ color: '#737373', fontSize: '1.1vw' }}>
          <span style={{ color: '#72a5af', fontWeight: 'bold' }}>
            {savedNumber}&nbsp;&nbsp;
          </span>
          Saved
        </span>
      </Flex>
    </Card>
  );
};
export default CountingButton;
