import React from 'react';
import { Row, Col, Space, Card, Image, Divider } from 'antd';
import BibleVerse from './component/bibleVerse';
import CountingButton from './component/countingButton';
import BookStoreButton from './component/bookStoreButton';
import Video from './component/embedVideo';

const MainPage: React.FC = () => {
  return (
    <div style={{ backgroundColor: '#ffffff', border: 'none' }}>
      <Row align={'middle'} justify="center">
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <CountingButton />
        </Col>
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <BibleVerse />
        </Col>
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <BookStoreButton />
        </Col>
      </Row>
      <Divider style={{ marginTop: '4vh' }} />
      <Video />
      <Divider />
    </div>
  );
};
export default MainPage;
