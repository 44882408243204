import { Effect, Reducer } from 'umi';
import {
  requestToFetchCountingNumber,
  requestToIncreaseCountingNumber,
  requestToFetchVerse,
} from './service';
import { VerseState } from './data';

export interface generralResponseBody {
  success: boolean;
  message: string;
  data: any;
}

export interface MainState {
  savedNumber: number;
  saved: boolean;
  dailyVerse: VerseState;
}

export interface ModelType {
  namespace: string;
  state: MainState;
  effects: {
    fetchSavedNumber: Effect;
    updateSavedNumber: Effect;
    fetchDailyVerse: Effect;
  };
  reducers: {
    querySavedNumber: Reducer;
    querySaved: Reducer;
    queryDailyVerse: Reducer;
  };
}

const model: ModelType = {
  namespace: 'main',
  state: {
    savedNumber: 0,
    saved: false,
    dailyVerse: {
      timeStamp: '',
      _id: -1,
      fromContent: 'John 3:16',
      content:
        'For God so loved the world, that he gave his only begotten Son, that whosoever believeth in him should not perish, but have everlasting life.',
    },
  },
  effects: {
    *fetchSavedNumber(_, { call, put }): any {
      const response: generralResponseBody = yield call(
        requestToFetchCountingNumber,
      );
      if (response && response.success) {
        yield put({
          type: 'querySavedNumber',
          payload: response.data,
        });
      }
    },
    *updateSavedNumber({ payload }, { call, put }) {
      const response: generralResponseBody = yield call(
        requestToIncreaseCountingNumber,
        payload,
      );
      if (response && response.success) {
        yield put({
          type: 'querySavedNumber',
          payload: response.data,
        });
        yield put({
          type: 'querySaved',
          payload: true,
        });
      }
    },
    *fetchDailyVerse(_, { call, put }): any {
      const response: generralResponseBody = yield call(requestToFetchVerse);
      if (response && response.success) {
        yield put({
          type: 'queryDailyVerse',
          payload: response.data,
        });
      }
    },
  },
  reducers: {
    querySavedNumber(state, action) {
      return {
        ...state,
        savedNumber: action.payload,
      };
    },
    querySaved(state, action) {
      return {
        ...state,
        saved: action.payload,
      };
    },
    queryDailyVerse(state, action) {
      return {
        ...state,
        dailyVerse: action.payload,
      };
    },
  },
};
export default model;
