import React from 'react';
import { Row, Col, Button, ConfigProvider, Image, Card, Flex } from 'antd';

const BookStoreButton: React.FC = () => {
  return (
    <Card
      style={{
        height: '30vh',
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
      }}
      styles={{
        body: {
          overflow: 'hidden',
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
        },
      }}
      bordered={false}
    >
      <Flex justify="center">
        <img
          alt="bookstore.png"
          src="/the7thday_bookstore.png"
          style={{ display: 'block', width: '12vw' }}
        />
      </Flex>
      <Flex vertical align="center" justify="center">
        <ConfigProvider
          theme={{
            components: {
              Button: {
                defaultColor: '#737373',
                defaultBorderColor: '#737373',
                defaultHoverColor: '#a29179',
                defaultHoverBorderColor: '#737373',
              },
            },
          }}
        >
          <Button
            style={{
              borderRadius: '10px',
              width: '7vw',
              height: '5.5vh',
              fontSize: '1.1vw',
              fontWeight: 'bold',
            }}
            onClick={() => {
              window.open('https://payhip.com/The7thDay', '_target');
            }}
          >
            Enter
          </Button>
        </ConfigProvider>
      </Flex>
    </Card>
  );
};
export default BookStoreButton;
