import { request } from 'umi';

export async function requestToFetchCountingNumber() {
  return request(`/get-saved-number`, {
    method: 'GET',
  });
}

export async function requestToIncreaseCountingNumber(params: any) {
  return request(`/increase-saved-number`, {
    method: 'PUT',
    data: {
      source: params.source,
    },
  });
}

export async function requestToFetchVerse() {
  return request(`/verses/get-verse-page-content`, {
    method: 'GET',
  });
}
