import React, { useEffect } from 'react';
import { Row, Typography, Image } from 'antd';
import { useDispatch, useSelector } from 'umi';
import { ConnectState } from '@/layouts/connect';

const { Paragraph } = Typography;

const BibleVerse: React.FC = () => {
  const dispatch = useDispatch();
  const { dailyVerse } = useSelector((state: ConnectState) => state.main);

  useEffect(() => {
    dispatch({
      type: 'main/fetchDailyVerse',
    });
  }, []);

  return (
    <>
      <Row align={'middle'} justify={'center'}>
        <Paragraph>
          <Row
            justify={'start'}
            style={{ marginLeft: '-2vw', marginBottom: '-2vh' }}
          >
            <Image src="/quotation_1.png" width={'2vw'}></Image>
          </Row>
          <p
            className="font-type-daily-verse"
            style={{ color: '#737373', fontSize: '2vw' }}
          >
            {dailyVerse.fromContent + ' - '}
          </p>
          <p
            className="font-type-daily-verse"
            style={{ color: '#737373', fontSize: '1.7vw', width: '27vw' }}
          >
            {dailyVerse.content}
          </p>
          <Row
            justify={'end'}
            style={{ marginRight: '-2vw', marginTop: '-2vh' }}
          >
            <Image src="/quotation_2.png" width={'2vw'}></Image>
          </Row>
        </Paragraph>
      </Row>
    </>
  );
};
export default BibleVerse;
