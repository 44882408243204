import React from 'react';
import { Row } from 'antd';

const Video: React.FC = () => {
  return (
    <Row align={'middle'} justify={'center'}>
      <iframe
        width="560vw"
        height="315vh"
        src="https://www.youtube.com/embed/z3-EytPQkFA?si=LIq8EcMxq4gy83DL"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        style={{ borderRadius: '20px' }}
      ></iframe>
    </Row>
  );
};
export default Video;
